<template>
  <base-layout>
    <details-page-title
      :title="$t('components.voucherManagement.headline')"
      dropdown-text-button="Voucher Status"
      :details-id="id"
    >
      <div class="flex items-center gap-4">
        <button
          @click="
            onEdgeStack({
              esId: invite.esId,
              primaryKey: id,
              rawData: details,
            })
          "
          class="
                flex
                items-center
                justify-between
                px-4
                py-0
                font-medium
                border
                rounded-full
                shadow-sm                
                h-35px
                text-14px text-oWhite
                focus:border-border-oWhite                
                focus:outline-none
                
              "
          :class="[
            details.status === 'Active'
              ? 'bg-blue-600 hover:bg-blue-700'
              : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed',
          ]"
        >
          <div class="flex items-center ">
            <i class="mr-2 fas fa-paper-plane"></i>
            <span>
              {{ $t('components.voucherManagement.actions.notifyRider') }}
            </span>
          </div>
        </button>
        <MoreActions :current-status="details.status" @change="UpdateStatus" />
      </div>
    </details-page-title>
    <DetailsPageHeader :currencySymbol="currencySymbol" :voucher="details" />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="
              $t('components.voucherManagement.details.voucherHistory.headline')
            "
            :hidePlus="true"
          />

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>

        <div class="summary-card-container">
          <!-- <SummaryCard
            v-for="summary in getSummaries"
            :key="`SummaryCard-` + summary.title"
            :title="summary.title"
            :value="summary.value"
            :variant="summary.variant"
            :icon="summary.variant"
          /> -->
          <SummaryCard
            :title="
              $t(
                'components.voucherManagement.details.voucherHistory.summary.invited'
              )
            "
            :value="indexMetaData.summary.total_invited_voucher"
            variant="gray"
          />

          <SummaryCard
            :title="
              $t(
                'components.voucherManagement.details.voucherHistory.summary.redeemed'
              )
            "
            :value="indexMetaData.summary.total_redeemed_voucher"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.voucherManagement.details.voucherHistory.summary.pending'
              )
            "
            :value="indexMetaData.summary.total_pending_voucher"
            variant="gray"
          />
          <SummaryCard
            :title="
              $t(
                'components.voucherManagement.details.voucherHistory.summary.expired'
              )
            "
            :value="indexMetaData.summary.total_expired_voucher"
            variant="gray"
          />
        </div>
      </div>

      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            textFallbackAlways
          >
            <FSTableRowItem :text="`#${item.id}`" :truncate="-5" />

            <FSTableRowItem
              :text="item.rider.full_name"
              :to="{
                name: 'ViewRiderUserProfile',
                params: { id: item.rider.id },
              }"
            />
            <FSTableRowItem>
              {{ item.redeemed_at | friendlyDateTime }}
              <p v-if="item.redeemed_at">
                (<TimeAgo
                  :title="item.redeemed_at"
                  :datetime="item.redeemed_at"
                  :auto-update="60"
                />)
              </p>
              <p v-else>--</p>
              <!-- {{ getRedeemedAt(item.redeemed_at) }} -->
            </FSTableRowItem>
            <FSTableRowItem>
              {{ showVoucherCredit(item.voucher) }}
            </FSTableRowItem>
            <FSTableRowItem>
              {{ item.expires_at | friendlyDateTime }}
              <p>
                (<TimeAgo
                  :title="item.expires_at"
                  :datetime="item.expires_at"
                  :auto-update="60"
                />)
              </p>
            </FSTableRowItem>

            <FSTableRowItem>
              <x-status
                :text="item.status"
                :variant="getItemVarient(item.status)"
              />
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
      <NotifyRider
        :esId="invite.esId"
        :busy="invite.busy"
        :riderData="rider"
        :rawData="details"
        :fleets="fleets"
        :primaryKey="invite.primaryKey"
        :fleetList="fleetList"
        :currencies="currencies"
        @edgestack="
          onEdgeStack({ esId: addEdit.esId, primaryKey: $event.taskId })
        "
      />
    </content-section>
  </base-layout>
</template>

<script>
import { formatSummaries } from './helpers'
import DetailsPageHeader from './DetailsPageHeader.vue'
import xMan from '@/utils/xMan'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import SummaryCard from '@/components/cards/SummaryCard'
import XStatus from '@/components/badge/XStatus'
import { VoucherConfig } from '@/config/VoucherConfig'
import ContentSection from '@/components/layout/ContentSection'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import TitlePlus from '@/components/ui/TitlePlus.vue'
import NotifyRider from '../NotifyRider'
import MoreActions from './MoreActions'
import { useEdgeStack } from '@/plugins/edgeStack'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'VoucherDetails',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    SummaryCard,
    DateRangePicker,
    XStatus,
    TitlePlus,
    ContentSection,
    NotifyRider,
    MoreActions,
  },
  data() {
    return {
      details: {},
      indexMetaData: {
        summary: {
          total_invited_voucher: 0,
          total_redeemed_voucher: 0,
          total_pending_voucher: 0,
          total_expired_voucher: 0,
        },
      },
      invite: {
        esId: 'notifyRider',
        busy: false,
        rawData: null,
        vehicleOptions: null,
        primaryKey: null,
      },
      qso: { append: '', prepend: '?' },
      fstId: 'voucherSummary',
      filterOptions: [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Invited', value: 'Invited' },
            { text: 'Redeemed', value: 'Redeemed' },
            { text: 'Pending', value: 'Pending' },
            { text: 'Expired', value: 'Expired' },
          ],
          title: 'Status',
        },
      ],
      id: '',
      indexDataEndpoint: '',
      tableHeaders: [
        {
          text: this.$t(
            'components.voucherManagement.details.voucherHistory.table.columns.redeemId'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.voucherManagement.details.voucherHistory.table.columns.riderName'
          ),
          width: '20%',
          sort: 'title',
        },
        {
          text: this.$t(
            'components.voucherManagement.details.voucherHistory.table.columns.redeemedAt'
          ),
          width: '10%',
          sort: 'redeemed_at',
        },
        {
          text: this.$t(
            'components.voucherManagement.details.voucherHistory.table.columns.leftCredit'
          ),
          width: '10%',
          sort: 'balance',
        },
        {
          text: this.$t(
            'components.voucherManagement.details.voucherHistory.table.columns.expiredAt'
          ),
          width: '10%',
          sort: 'expires_at',
        },
        {
          text: this.$t(
            'components.voucherManagement.details.voucherHistory.table.columns.status'
          ),
          width: '15%',
          sort: 'status',
        },
      ],
      dateFormat: {
        format: 'DD MMM YY',
      },
      fleets: [],
      rider: {
        assigned_fleets: [],
        invitation_type: false,
        rider: '',
        selected_riders: [],
      },
      fleetList: [],
      currencies: [],
    }
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },
  created() {
    this.id = this.$route.params.id
    this.indexDataEndpoint = VoucherConfig.api.history(this.id)
    this.getVoucherDetails()
    this.getFleets()
  },
  methods: {
    async getFleets() {
      let { data } = await this.$http.get('/dashboard/fleets/?dropdown')
      if (data) {
        this.fleetList = data.data
        const currencyList = []
        data.data.forEach((fleet) => {
          const result = currencyList.find(
            (row) => fleet.country.code3 === row.text
          )
          if (!result) {
            currencyList.push({
              text: fleet.country.code3,
              value: fleet.country.currency_symbol,
            })
          }
        })
        this.currencies = currencyList
      }
    },
    async onEdgeStack({
      esId = this.invite.esId,
      esAction = 'toggle',
      // eslint-disable-next-line no-unused-vars
      primaryKey = null,
      rawData = null,
    } = {}) {
      if (this.$acl.canNotUpdate('voucher')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'notify',
            permission: 'voucher',
          })
        )
        return
      }
      if (['Expired', 'Inactive'].includes(this.details.status)) {
        if (this.details.status === 'Expired') {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Expired Voucher!',
              text: "You can't notify riders for expired voucher.",
            },
            5000
          )
        }
        if (this.details.status === 'Inactive') {
          this.$notify(
            {
              group: 'generic',
              type: 'default',
              title: 'Inactive Voucher!',
              text:
                "You can't notify riders for inactive voucher. Please activate the voucher first.",
            },
            5000
          )
        }
        return
      } else {
        const edgeStack = useEdgeStack()
        this.rider.rider = ''
        this.rider.invitation_type = false
        this.rider.assigned_fleets = []

        // open the edgestack first, load data asynchronally if primary key is provided
        if (esAction === 'toggle') {
          edgeStack.toggle(esId)
        } else if (esAction === 'open') {
          edgeStack.open(esId)
        } else if (esAction === 'close') {
          edgeStack.close(esId)
        } else {
          throw new Error(`Unknown esAction: ${esAction}`)
        }

        // immidiately make edgestack busy & fetch & provide data if primary key is provided
        if (primaryKey && esId === this.invite.esId) {
          // view mode
          this.invite.busy = true
          this.invite.primaryKey = primaryKey
          this.invite.rawData = rawData
          this.getFleetOptions()
        }
      }
    },
    getFleetOptions() {
      let fleets = this.details?.assigned_fleets.map(function(row) {
        return {
          text: row.name,
          value: row.id,
        }
      })
      this.fleets = fleets
    },
    onApplyFilterDateRange,
    getRedeemedAt(redeemed_at) {
      if (redeemed_at) {
        return this.$UTCAwareTime(redeemed_at, this.dateFormat)
      }
      return '--'
    },
    getItemVarient(status) {
      if (status === 'Invited') return 'blue'
      if (status === 'Redeemed') return 'green'
      if (status === 'Pending') return 'orange'
      return 'red'
    },
    showVoucherCredit(voucher) {
      if (voucher.voucher_type) {
        let prefix =
          voucher.voucher_type === 'RV_CREDIT' ? this.currencySymbol : ''
        let postfix = voucher.voucher_type === 'RV_CREDIT' ? '' : 'mins'
        let amount =
          voucher.voucher_type === 'RV_CREDIT'
            ? voucher.reward_amount
            : parseInt(voucher.reward_amount)

        return `${prefix}${amount} ${postfix}`
      }
      return ''
    },
    async getVoucherDetails() {
      let { data } = await this.$http.get(VoucherConfig.api.detail(this.id))
      if (data) {
        this.details = data
      }
    },
    async UpdateStatus(status) {
      if (this.$acl.canNotUpdate('voucher')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'voucher',
          })
        )
        return
      }
      this.isLoading = true
      let data = new xMan({ is_active: status }).toFormData()
      await this.$http
        .patch(VoucherConfig.api.update(this.id), data)
        .then((res) => {
          console.log(res)
          this.getVoucherDetails()
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Voucher status has updated',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('id-ver-err', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update status',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
      return true
    },
  },
}
</script>
